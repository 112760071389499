@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 8px;
    /* Adjust the width */
}

/* Customize the track */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Customize the thumb (scroll handle) */
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

/* Change thumb color on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}